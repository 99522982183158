import React from 'react'
import {Link} from 'gatsby'
import {Carousel} from 'react-bootstrap'
import Styled from 'styled-components'

import zdj1 from '../images/photos/5.jpg'
import zdj2 from '../images/photos/10.jpg'
import zdj3 from '../images/photos/21.jpg'

const Karuzela = () =>{
    return(
        <StyledCarousel>
            <Carousel.Item>
                <Link to="/gallery">
                    <StyledImg
                    src={zdj1}
                    alt="First slide"
                    />
                </Link>
            </Carousel.Item>
            <Carousel.Item>
                <Link to="/gallery">
                    <StyledImg
                    src={zdj2}
                    alt="Third slide"
                    />
                </Link>
            </Carousel.Item>
            <Carousel.Item>
                <Link to="/gallery">
                    <StyledImg
                    src={zdj3}
                    alt="Third slide"
                    />
                </Link>
            </Carousel.Item>
        </StyledCarousel>
    )
}

export default Karuzela; 

const StyledImg = Styled.img`
    display:block;
    max-width:1000px !important;
    max-height: 80vh;
    width:100%;
`

const StyledCarousel= Styled(Carousel)`
    .carousel-inner{
        max-width:1000px;
    }
`