import React from 'react'
import Styled from 'styled-components'
import {Row, Col} from 'react-bootstrap'

import Carousel from './Karuzela'

import NapiszDoNas from './Buttons/napiszDoNas'
import ZobaczWiecej from './Buttons/zobaczWiecej'

const NaszeRealizacje = () => {

  return(
    <StyledSection>
      <H2>NASZE REALIZACJE</H2>
      <Row xs={12} className="justify-content-center">
        <Carousel/>
      </Row>
      <Row xs={12} className=" d-flex justify-content-center">
        <StyledCol xs={12} sm={6}>
          <NapiszDoNas />
        </StyledCol>
        <StyledCol xs={12} sm={6}>
          <ZobaczWiecej/>
        </StyledCol>
      </Row>
    </StyledSection>
  )
}

export default NaszeRealizacje

const StyledSection = Styled.section`
  margin-bottom:4%;
`;

const H2 = Styled.h2`
  font-weight: 700;
  font-size: 24px;
`;

const StyledCol = Styled(Col)`
  max-width:500px;
  padding:0 0;
  @media only screen and (max-width:992px){
    padding:10px 0 0 0;
  }
`



