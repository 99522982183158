import React from 'react'
import {Link} from 'gatsby'
import Styled from 'styled-components'

const zobaczWiecej = () =>{
    return(
        <StyledLink to="/gallery">
            ZOBACZ WIĘCEJ!
        </StyledLink>
    )
}

export default zobaczWiecej

const StyledLink = Styled(Link)`
    color: white;
    font-weight: bold;
    background-color: var(--blue);
    font-size: 1.6em;
    display:block;
    margin-left:auto;
    margin-right:auto;
    text-align:center;
    padding: 10px 0 10px 0;


`

